import { darken, desaturate, lighten } from 'polished';
import * as React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import qs from 'query-string';

import { colors } from '../../styles/colors';
import config from '../../website-config';

const SubscribeFormStyles = css`
  @media (max-width: 500px) {
    -ms-flex-direction: column;
    flex-direction: column;
  }
`;

const SubscribeEmail = styled.input`
  display: block;
  padding: 14px 20px;
  width: 100%;
  /* border: color(var(--lightgrey) l(+7%)) 1px solid; */
  border: ${lighten('0.07', colors.lightgrey)};
  color: ${colors.midgrey};
  font-size: 14px;
  line-height: 1.14;
  font-weight: 400;
  user-select: text;
  border-radius: 2px;
  box-shadow: 0 2px 5px 0 rgba(31, 31, 33, 0.15);
  transition: border-color 0.15s linear;
  font-style: italic;
  color: #939498;

  -webkit-appearance: none;
  :focus {
    outline: 0;
    /* border-color: color(var(--lightgrey) l(-2%)); */
    border-color: ${darken('0.02', colors.lightgrey)};
  }
`;

const SubscribeFormButton = styled.button`
  display: inline-block;
  margin: 0 0 0 20px;
  padding: 0;
  width: 124px;
  height: 43px;
  outline: none;
  color: #fff;
  text-align: center;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.1);
  font-size: 14px;
  font-weight: 700;
  line-height: 1.14;
  border-radius: 2px;
  box-shadow: 0 2px 5px 0 rgba(31, 31, 33, 0.15);
  background-color: #f94f3f;
  text-transform: uppercase;

  -webkit-font-smoothing: subpixel-antialiased;

  :active,
  :focus {
    /* background: color(var(--blue) lightness(-9%) saturation(-10%)); */
    background: ${desaturate('0.1', darken('0.09', colors.blue))};
  }
  @media (max-width: 500px) {
    margin: 10px 0 0;
    width: 100%;
  }
`;

const FormGroup = styled.div`
  flex-grow: 1;
  @media (max-width: 500px) {
    width: 100%;
  }
`;

const SubscribeForm: React.FC = () => {
  type HiddenGroups = [
    {
      catId: string;
      index: number;
      value: string;
    },
  ];
  const groupFormAttrs = (CONFIG.mailchimpHiddenGroups as HiddenGroups).map(x => ({
    name: `group[${x.catId}][${x.value}]`,
    id: `mce-group[${x.catId}]-${x.catId}-${x.index}`,
    value: x.value,
  }));

  return (
    <form
      noValidate
      css={SubscribeFormStyles}
      action={config.mailchimpAction + '&' + qs.stringify(CONFIG.mailchimpHiddenFields)}
      method="post"
      id="mc-embedded-subscribe-form"
      name="mc-embedded-subscribe-form"
      target="_blank"
    >
      {/* This is required for the form to work correctly  */}
      <FormGroup className="form-group">
        <SubscribeEmail
          className="subscribe-email"
          type="email"
          name={config.mailchimpEmailFieldName}
          id={config.mailchimpEmailFieldName}
          placeholder="Your email"
        />
      </FormGroup>
      <div style={{ display: 'none' }}>
        {groupFormAttrs.map(x => (
          <input type="checkbox" checked readOnly key={x.id} {...x} />
        ))}
      </div>
      <div style={{ position: 'absolute', left: '-5000px' }} aria-hidden="true">
        <input type="text" name={config.mailchimpName} tabIndex={-1} />
      </div>
      <SubscribeFormButton type="submit">
        <span>Subscribe</span>
      </SubscribeFormButton>
    </form>
  );
};

export default SubscribeForm;
