import { Link } from 'gatsby';
// import { setLightness } from 'polished';
import * as React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/core';

// import { colors } from '../styles/colors';
import { outer, inner } from '../styles/shared';
import config from '../website-config';
import Facebook from './icons/facebook';
import Twitter from './icons/twitter';

const SiteFooter = css`
  position: relative;
  padding-top: 52px;
  padding-bottom: 53px;
  color: #fff;
  background-color: #efeded;
  border-top: solid 1px;
  border-color: rgba(147, 148, 152, 0.2);
`;

const SiteFooterContent = css`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  color: rgba(147, 148, 152, 0.7);
  font-size: 12px;
  font-weight: 500;
  line-height: 1.17;

  svg {
    fill: #939498;
  }

  .facebook svg {
    height: 14px;
    width: 14px;
  }

  .twitter svg {
    height: 14px;
  }

  a {
    color: rgba(147, 148, 152);
  }

  a:hover,
  a:hover svg {
    opacity: 0.8;
    text-decoration: none;
  }

  @media (max-width: 650px) {
    flex-direction: column;
  }
`;

const SiteFooterNav = styled.nav`
  display: flex;

  a {
    position: relative;
    margin-left: 30px;
  }

  a:before {
    content: '';
    position: absolute;
    top: 11px;
    left: -11px;
    display: block;
    width: 2px;
    height: 2px;
    background: #fff;
    border-radius: 100%;
  }

  a:first-of-type:before {
    display: none;
  }
  @media (max-width: 650px) {
    a:first-of-type {
      margin-left: 0;
    }
  }

  @media (max-width: 400px) {
    padding-top: 15px;
  }
`;

const Footer: React.FC = () => {
  return (
    <footer css={[outer, SiteFooter]}>
      <div css={[inner, SiteFooterContent]}>
        <section className="copyright">
          <Link to="/">&copy; {config.title}</Link>
        </section>
        <SiteFooterNav>
          {config.facebook && (
            <a
              href={config.facebook}
              target="_blank"
              title="Facebook"
              rel="noopener noreferrer"
              className="facebook"
            >
              <Facebook />
            </a>
          )}
          {config.twitter && (
            <a
              href={config.twitter}
              title="Twitter"
              target="_blank"
              rel="noopener noreferrer"
              className="twitter"
            >
              <Twitter />
            </a>
          )}
        </SiteFooterNav>
      </div>
    </footer>
  );
};

export default Footer;
