export interface WebsiteConfig {
  title: string;
  description: string;
  coverImage: string;
  logo: string;
  /**
   * Specifying a valid BCP 47 language helps screen readers announce text properly.
   * See: https://dequeuniversity.com/rules/axe/2.2/valid-lang
   */
  lang: string;
  /**
   * blog full path, no ending slash!
   */
  siteUrl: string;
  fbAppId: string;
  /**
   * full url, no username
   */
  facebook: string;
  /**
   * full url, no username
   */
  twitter: string;
  /**
   * hide or show all email subscribe boxes
   */
  showSubscribe: boolean;
  /**
   * create a list on mailchimp and then create an embeddable signup form. this is the form action
   */
  mailchimpAction?: string;
  /**
   * this is the hidden input field name
   */
  mailchimpName?: string;
  /**
   * name and id of the mailchimp email field
   */
  mailchimpEmailFieldName?: string;
  /**
   * id of the group category with the source group to assign to subscribers
   */
  mailchimpGroupCategory?: number;
  /**
   * Appears alongside the footer, after the credits
   */
  footer?: string;
}

const websiteConfig: WebsiteConfig = {
  title: CONFIG.title,
  description:
    "We translate the world's content into other languages to help content creators reach wider audiences!",
  coverImage: 'img/wallpaper.png',
  logo: 'img/logo_white.png',
  lang: 'en',
  siteUrl: CONFIG.siteUrl,
  fbAppId: '530356127557452',
  facebook: 'https://www.facebook.com/PapercupAI',
  twitter: 'https://twitter.com/PapercupAI',
  showSubscribe: true,
  mailchimpAction:
    'https://papercup.us18.list-manage.com/subscribe/post?u=288b8de8d6a6034ae6d92488c&amp;id=822b746de4',
  mailchimpName: 'b_288b8de8d6a6034ae6d92488c_822b746de4',
  mailchimpEmailFieldName: 'EMAIL',
};

export default websiteConfig;
